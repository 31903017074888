import { Li, LoadingButton, Text, Title, Ul } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Link, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, openBrowser } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, differenceInHours, format, parseISO } from "date-fns";
import { useEffect, useState } from "react";

import { DateTimePicker } from "../../../lib/DatePicker";
import { useScheduleAssessment } from "../../api/useScheduleAssessment";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment } from "../../types";
import { adjustMinimumDatePickerTime } from "../../utils/adjustMinimumDatePickerTime";

interface Props {
  assessment: SkillsAssessment;
  dueDate?: string;
  qualification?: string;
  onSuccess?: () => void;
}

export function ScheduleAssessment(props: Props) {
  const { assessment, dueDate, qualification, onSuccess = () => undefined } = props;

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const { minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours } =
    scheduledAssessmentConfig;

  const [scheduledFor, setScheduledFor] = useState<string | undefined>(undefined);

  const worker = useDefinedWorker();
  const { mutateAsync: scheduleAssessment, isLoading } = useScheduleAssessment();

  const [minDate, setMinDate] = useState(
    addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours)
  );
  const [maxDate, setMaxDate] = useState(
    addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours)
  );

  // update the min/max time every 10 seconds, otherwise the date picker will show dates that are no longer valid if the user spends a long time on the page
  useEffect(() => {
    const secondsInterval = 10;
    const intervalId = setInterval(() => {
      setMinDate(addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours));
      setMaxDate(addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours));
    }, secondsInterval * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours]);

  return (
    <Stack justifyContent="space-between" width="100%" height="100%">
      <Stack spacing={4}>
        <Title component="h1">
          Schedule your assessment and return here at that time to take it.
        </Title>
        <Ul>
          <Li>
            <Text bold sx={{ lineHeight: "24px" }}>
              You need to complete this assessment to continue booking shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              It takes about {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes}{" "}
              minutes and must be completed{" "}
              {isDefined(dueDate) ? `by ${format(parseISO(dueDate), "MMMM do")}` : ""} for you to
              continue booking shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              You&apos;ll answer questions pertaining to your knowledge and experience
              {isDefined(qualification) ? ` as a ${qualification}` : ""} over video.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Tap{" "}
              <Link
                onClick={() => {
                  void openBrowser(skillsAssessmentConfig.supportArticleLink);
                }}
              >
                here
              </Link>{" "}
              for additional details.
            </Text>
          </Li>
        </Ul>
        <Stack spacing={3}>
          {isDefined(scheduledFor) ? (
            <Text sx={{ lineHeight: "24px" }}>You are scheduling your assessment for:</Text>
          ) : (
            <Text sx={{ lineHeight: "24px" }}>Book your assessment for the following time:</Text>
          )}
          <DateTimePicker
            minDateTime={adjustMinimumDatePickerTime(minDate)}
            maxDateTime={maxDate}
            disabled={isLoading}
            onConfirm={(value) => {
              setScheduledFor(value.toISOString());
            }}
          />
        </Stack>
      </Stack>
      <LoadingButton
        variant="contained"
        disabled={isLoading || !isDefined(scheduledFor)}
        isLoading={isLoading}
        onClick={async () => {
          await scheduleAssessment({
            assessmentId: assessment.id,
            workerId: worker.userId,
            scheduledFor: scheduledFor!,
          });
          logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_EVENT, {
            assessmentId: assessment.id,
            workerId: worker.userId,
            scheduledFor: scheduledFor!,
            hoursUntilScheduled: differenceInHours(parseISO(scheduledFor!), new Date()),
          });

          onSuccess();
        }}
      >
        Confirm
      </LoadingButton>
    </Stack>
  );
}
